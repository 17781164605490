import React from "react";
import Content1 from "../Components/LandingPage/Content1";
import Main from "../Components/LandingPage/Main";

const LandingPage = () => {
  return (
    <>
        <Main />
        <Content1 />
    </>
  );
};

export default LandingPage;
